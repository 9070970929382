body {
  background-color: #eee;
}

.App {
  text-align: center;
}

.outerWrapper {
  width: 100%;
}

.innerWrapper {
  width: 100%;
}

.thankYou {
  font-family: Source Sans Pro,SourceSansPro,sans-serif;
  font-weight: lighter;
  text-align: center;
  margin-top: 100px;
  font-size: 50px;
  font-style: normal;
  background-color: white;
  padding: 40px 20px;
  box-shadow: 0px 0px 5px darkgrey;
}

.headerText {
  font-size: 20px;
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
}

.comment {
  margin-bottom: 20px;
}

@media screen and (max-width: 420px) {
  .thankyou {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 40px 0;
  }
}
