.commentBox {
  display: flex;
  flex-direction: column;
}

textarea {
  height: 200px;
  font-family: Source Sans Pro,SourceSansPro,sans-serif;
  font-size: 18px;
  width: auto;
  min-width: 400px;
  margin: 10px auto;
}

textarea:disabled {
  background-color: white;
}

.commentBox button {
  height: 35px;
  width: 170px;
  margin: 0 auto;
  font-weight: bold;
  background-color: white;
  border-radius: 7px;
  border: 1px black solid;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.commentBox button span {
  margin-left: 5px;
}

.commentBox button:hover {
  cursor: pointer;
  border: 1px rgb(0, 188, 212) solid;
  color: rgb(0, 188, 212);
}

.commentBox button:disabled {
  border: 1px grey solid;
  color: grey;
  cursor: default;
}

.submitted {
  text-align: center;
}

@media screen and (max-width: 420px) {
  .commentBox textarea {
    min-width: 95%;
    margin: 20px auto;
  }

  .commentBox button {
    min-width: 70%;
  }
}
