.socialLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.socialLinks button,
.socialLinks button:active {
  margin: 3px;
  border: none;
  border-radius: 5px;
  padding: 3px;
}

.socialLinks button:hover {
  cursor: pointer;
}

.socialLinks button img {
  height: 60px;
  width: 60px;
}
